
import Vue from 'vue';
import type { Location } from 'vue-router';
import { LocaleMessages } from 'vue-i18n';
import { mapGetters, mapState } from 'vuex';
import { EngagementAbility, FeatureFlags, LicenseType } from '@/helpers/constants';
import { getDomainForCookies } from '@/helpers/url';
import { accessToken, companyId } from '@/services/cookies';

const {
  VUE_APP_ENGAGEMENTADMIN_ADDRESS,
  VUE_APP_TSXASSADMIN_ADDRESS,
  VUE_APP_ARCHIVIST_ADDRESS,
  VUE_APP_GATEKEEPER_ADDRESS,
} = process.env;

function getEngagementAdminUrl(relativeUrl: string): string {
  return new URL(relativeUrl, VUE_APP_ENGAGEMENTADMIN_ADDRESS).href;
}

function getTsxassAdminUrl(relativeUrl: string): string {
  return new URL(relativeUrl, VUE_APP_TSXASSADMIN_ADDRESS).href;
}

function getArchivistUrl(relativeUrl: string): string {
  return new URL(relativeUrl, VUE_APP_ARCHIVIST_ADDRESS).href;
}

interface AsideMenuItem {
  title: string | LocaleMessages;
  href?: string;
  to?: Location;
  icon: string;
  isVisible: boolean;
  group?: string;
  is360?: boolean;
}

export default Vue.extend({
  name: 'AppAsideLeft',

  inject: ['RouteNames'],

  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    ...mapState('engagementInfo', {
      abilities: 'abilities',
    }),
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    ...mapGetters('featureFlags', ['hasFlag']),
    isNewLeftMenu() {
      return this.hasFlag(FeatureFlags.NEW_LEFT_MENU);
    },
    menuItems() {
      return this.isNewLeftMenu ? this.engagementMenuItemsV2 : [this.engagementMenuItems];
    },
    engagementMenuItems(): AsideMenuItem[] {
      const fullList: AsideMenuItem[] = [
        {
          title: this.$t('navMenu.feedbackFromColleagues'),
          to: { name: this.RouteNames.R_APP_FEEDBACK_FROM_COLLEAGUES },
          icon: 'fal fa-handshake',
          isVisible: this.licenses.includes(LicenseType.TSXASS),
        },
        {
          title: this.$t('navMenu.userSurveys'),
          href: getEngagementAdminUrl('/app/user_surveys'),
          icon: '$listAltLight',
          isVisible: this.can(EngagementAbility.CAN_SEE_USER_SURVEYS_LEFT_MENU),
        },
        {
          title: this.$t('navMenu.results'),
          to: { name: this.RouteNames.R_APP_USER_ANALYTICS },
          icon: 'fal fa-comment',
          isVisible: this.licenses.includes(LicenseType.TSXASS),
        },
        {
          title: this.$t('navMenu.surveys'),
          href: getEngagementAdminUrl('/app/surveys'),
          icon: '$chartPie',
          isVisible: this.can(EngagementAbility.CAN_SEE_SURVEYS_LEFT_MENU),
        },
        {
          title: this.$t('navMenu.creation'),
          href: getEngagementAdminUrl('/app/construction'),
          icon: '$puzzleLight',
          isVisible: this.can(EngagementAbility.CAN_SEE_CONSTRUCTOR_LEFT_MENU),
        },
        {
          title: this.$t('navMenu.practices'),
          href: getEngagementAdminUrl('/app/hr_practices'),
          icon: '$bookLight',
          isVisible:
            this.licenses.includes(LicenseType.ENGAGEMENT__FULL)
            && this.can(EngagementAbility.CAN_SEE_HR_PRACTICES_LEFT_MENU),
        },
        {
          title: this.$t('navMenu.settings'),
          href: getEngagementAdminUrl('/app/settings'),
          icon: '$cog',
          isVisible: this.can(EngagementAbility.CAN_SEE_SETTINGS_LEFT_MENU),
        },
        {
          title: this.$t('navMenu.competencies'),
          href: getTsxassAdminUrl('/app/competencies'),
          icon: 'fal fa-folder-tree',
          isVisible:
            this.licenses.includes(LicenseType.TSXASS) && this.can(EngagementAbility.CAN_SEE_COMPETENCIES_LEFT_MENU),
        },
      ];
      return fullList.filter((value) => value.isVisible);
    },
    engagementMenuItemsV2(): AsideMenuItem[] {
      const fullList: AsideMenuItem[] = [
        {
          title: this.$t('navMenuV2.feedback'),
          to: { name: this.RouteNames.R_APP_FEEDBACK_FROM_COLLEAGUES },
          icon: 'fal fa-comments-alt',
          isVisible: this.licenses.includes(LicenseType.TSXASS),
          group: 'main',
          is360: true,
        },
        {
          title: this.$t('navMenuV2.userSurveys'),
          href: getEngagementAdminUrl('/app/user_surveys'),
          icon: 'fal fa-play-circle',
          isVisible: this.can(EngagementAbility.CAN_SEE_USER_SURVEYS_LEFT_MENU),
          group: 'main',
        },
        {
          title: this.$t('navMenuV2.creation'),
          href: getEngagementAdminUrl('/app/construction'),
          icon: 'fal fa-plus-square',
          isVisible: this.can(EngagementAbility.CAN_SEE_CONSTRUCTOR_LEFT_MENU),
          group: 'assessment',
        },
        {
          title: this.$t('navMenuV2.surveys'),
          href: getEngagementAdminUrl('/app/surveys'),
          icon: 'fal fa-square-kanban',
          isVisible: this.can(EngagementAbility.CAN_SEE_SURVEYS_LEFT_MENU),
          group: 'assessment',
        },
        {
          title: this.$t('navMenuV2.practices'),
          href: getEngagementAdminUrl('/app/hr_practices'),
          icon: 'fal fa-book',
          isVisible:
            this.licenses.includes(LicenseType.ENGAGEMENT__FULL)
            && this.can(EngagementAbility.CAN_SEE_HR_PRACTICES_LEFT_MENU),
          group: 'assessment',
        },
        {
          title: this.$t('navMenuV2.results'),
          to: { name: this.RouteNames.R_APP_USER_ANALYTICS },
          icon: 'fal fa-file-chart-pie',
          isVisible: this.licenses.includes(LicenseType.TSXASS),
          group: 'assessment',
          is360: true,
        },
        {
          title: this.$t('navMenuV2.competencies'),
          href: getTsxassAdminUrl('/app/competencies'),
          icon: 'fal fa-folder-tree',
          isVisible:
            this.licenses.includes(LicenseType.TSXASS) && this.can(EngagementAbility.CAN_SEE_COMPETENCIES_LEFT_MENU),
          group: 'assessment',
          is360: true,
        },
        {
          title: this.$t('navMenuV2.myData'),
          href: getArchivistUrl('/app/profile'),
          icon: 'fal fa-user',
          isVisible: true,
        },
        {
          title: this.$t('navMenuV2.myTeam'),
          href: getArchivistUrl('/app/team'),
          icon: 'fal fa-users',
          isVisible: true,
        },
        {
          title: this.$t('navMenuV2.orgstructure'),
          href: getArchivistUrl('/app/orgstructure'),
          icon: 'fal fa-users-class',
          isVisible: true,
        },
        {
          title: this.$t('navMenuV2.settings'),
          href: getEngagementAdminUrl('/app/settings'),
          icon: 'fal fa-cog',
          isVisible: this.can(EngagementAbility.CAN_SEE_SETTINGS_LEFT_MENU),
        },
      ];
      return fullList.filter((value) => value.isVisible);
    },
    isMenuReady() {
      return this.licenses.length > 0 && this.abilities.length > 0;
    },
  },
  methods: {
    handleSignOut() {
      accessToken.remove({ domain: getDomainForCookies() });
      companyId.remove({ domain: getDomainForCookies() });
      // IMPORTANT: редиректим в гейткипер с сылкой на текущую страницу
      const url = new URL(`/?back=${window.location.origin}`, VUE_APP_GATEKEEPER_ADDRESS);
      window.location.replace(url.href);
    },
  },
});
